// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-cancelled-js": () => import("./../src/pages/payment-cancelled.js" /* webpackChunkName: "component---src-pages-payment-cancelled-js" */),
  "component---src-pages-payment-check-js": () => import("./../src/pages/payment-check.js" /* webpackChunkName: "component---src-pages-payment-check-js" */),
  "component---src-pages-payment-success-js": () => import("./../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-summer-2020-daycamp-js": () => import("./../src/pages/summer-2020/daycamp.js" /* webpackChunkName: "component---src-pages-summer-2020-daycamp-js" */),
  "component---src-pages-summer-2020-term-1-all-t-92-tn-js": () => import("./../src/pages/summer-2020/term1/all-t92tn.js" /* webpackChunkName: "component---src-pages-summer-2020-term-1-all-t-92-tn-js" */),
  "component---src-pages-summer-2020-term-1-exchange-class-js": () => import("./../src/pages/summer-2020/term1/exchangeClass.js" /* webpackChunkName: "component---src-pages-summer-2020-term-1-exchange-class-js" */),
  "component---src-pages-summer-2020-term-1-index-js": () => import("./../src/pages/summer-2020/term1/index.js" /* webpackChunkName: "component---src-pages-summer-2020-term-1-index-js" */),
  "component---src-pages-summer-2020-term-1-registration-js": () => import("./../src/pages/summer-2020/term1/registration.js" /* webpackChunkName: "component---src-pages-summer-2020-term-1-registration-js" */),
  "component---src-pages-summer-2020-term-2-classes-js": () => import("./../src/pages/summer-2020/term2/classes.js" /* webpackChunkName: "component---src-pages-summer-2020-term-2-classes-js" */),
  "component---src-pages-summer-2020-term-2-index-js": () => import("./../src/pages/summer-2020/term2/index.js" /* webpackChunkName: "component---src-pages-summer-2020-term-2-index-js" */),
  "component---src-pages-terms-summer-2020-js": () => import("./../src/pages/terms/summer-2020.js" /* webpackChunkName: "component---src-pages-terms-summer-2020-js" */),
  "component---src-pages-terms-summer-2020-term-2-js": () => import("./../src/pages/terms/summer-2020-term2.js" /* webpackChunkName: "component---src-pages-terms-summer-2020-term-2-js" */)
}

